import CheckoutQuery from 'Query/Checkout.query';
import { DETAILS_STEP } from 'Route/Checkout/Checkout.config';
import { isSignedIn } from 'Util/Auth';
import { getGuestQuoteId } from 'Util/Cart';
import { fetchMutation } from 'Util/Request';

import { deleteIncrementId, getIncrementId } from '../util/IncrementIDPersistence';
import MOLLIE_METHODS from '../util/MollieSupportedMethods';
import { setPaymentToken } from '../util/PaymentTokenPersistence';
import { getSelectedIssuer } from '../util/SelectedIssuerPersistence';

const __construct = (args, callback, instance) => {
    callback(...args);

    try {
        instance.setState({
            orderID: getIncrementId(),
            checkoutStep: DETAILS_STEP
        });
    } catch (Error) {
        /* No Mollie increment ID set, continue with the original flow. */
    }
};

const componentDidMount = (args, callback, instance) => {
    try {
        const orderID = getIncrementId();

        instance.setDetailsStep(orderID);

        deleteIncrementId();
    } catch (Error) {
        callback(...args);
    }
};

const savePaymentMethodAndPlaceOrder = async (args, callback, instance) => {
    const [paymentInformation] = args;

    if (!MOLLIE_METHODS.includes(paymentInformation.paymentMethod.code)) {
        return callback(...args);
    }

    const { consents } = instance.state;
    const { isNewAccountCreateChecked } = instance.state;
    let mollie_selected_issuer;
    try {
        mollie_selected_issuer = getSelectedIssuer(paymentInformation.paymentMethod.code);
    } catch (Error) {
        /* No selected issuer found, continue with the original flow. */
    }

    const { paymentMethod: { code, additional_data, purchase_order_number } } = paymentInformation;
    const guest_cart_id = !isSignedIn() ? getGuestQuoteId() : '';

    try {
        await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
            guest_cart_id,
            payment_method: {
                code,
                [code]: additional_data,
                purchase_order_number,
                mollie_selected_issuer
            }
        }));

        const create_account = isNewAccountCreateChecked;
        const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(guest_cart_id, consents, create_account));
        const { placeOrder: { order: { order_id, mollie_redirect_url, mollie_payment_token } } } = orderData;
        if (!isSignedIn() && isNewAccountCreateChecked) {
            instance.linkOrderToCustomer(order_id);
        }

        // instance.removeCartForMollie();

        if (!mollie_payment_token) {
            throw Error('Expected mollie_payment_token in order data, none found', orderData);
        }

        if (!mollie_redirect_url) {
            throw Error('Expected mollie_redirect_url in order data, none found', orderData);
        }

        setPaymentToken(mollie_payment_token);
        window.location.href = mollie_redirect_url;
        return {};
    } catch (e) {
        instance._handleError(e);
    }

    return callback(...args);
};

export default {
    'Route/Checkout/Container': {
        'member-function': {
            __construct,
            componentDidMount,
            savePaymentMethodAndPlaceOrder
        }
    }
};
